import axios from 'axios';
import router from '@/router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers : {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
  }
});

instance.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json';

    const token = localStorage.getItem('bearer_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
);

instance.interceptors.response.use(function(response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function(error) {
  if (error.response.status == 401) {
    localStorage.removeItem('bearer_token');
    router.push('/login');
  }
  return Promise.reject(error);
});


export default instance;
